// @ts-strict-ignore
import googleAnalytics from '@analytics/google-analytics';
import googleTagManager from '@analytics/google-tag-manager';
import segmentPlugin from '@analytics/segment';
import { Injectable } from '@angular/core';
import { Analytics, AnalyticsInstance } from 'analytics';
import { Client } from 'src/app/client/client.model';
import { environment } from 'src/environments/environment';

export enum AnalyticsEvent {
  DASHBOARD_MODE_SELECTION = 'dashboard-mode-selection',
  COMPANY_QUESTIONNAIRE_RESPONSES_EXPORT = 'company-questionnaire-responses-export',
  COMPANY_QUESTIONNAIRE_RESPONSES_SHOW_SCORES = 'company-questionnaire-responses-show-scores',
  CLIENT_UPLOAD_TEMPLATE_DOWNLOAD = 'client-upload-template-download',
  CLIENT_UPLOAD_FILE_LOAD = 'client-upload-file-load',
  UNHANDLED_ERROR = 'unhandled-error',
  HANDLED_ERROR = 'handled-error',
}

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private analytics: AnalyticsInstance;

  constructor() {
    const plugins = [];
    if (environment.analytics.ga.enabled) {
      plugins.push(
        googleAnalytics({
          measurementIds: [environment.analytics.ga.id],
        }),
      );
    }
    if (environment.analytics.gtm.enabled) {
      plugins.push(
        googleTagManager({
          containerId: environment.analytics.gtm.id,
        }),
      );
    }
    if (environment.analytics.segment.enabled) {
      plugins.push(
        segmentPlugin({
          writeKey: environment.analytics.segment.id,
        }),
      );
    }

    this.analytics = Analytics({
      app: environment.analytics.app,
      plugins,
    });
  }

  pageView(page: string): void {
    const data = {
      title: page,
    };
    this.analytics.page(data);
  }

  track(event: AnalyticsEvent, data?: unknown) {
    this.analytics.track(event, data);
  }

  identifyAsClient(userId: number, client: Client) {
    const traits = {
      clientId: client.id,
      clientName: client.name,
      enabledModes: client.enabledAssessmentModes,
      activeUntil: client.activeUntil,
      enabledQuestionnaires: client.enabledQuestionnaires,
    };
    this.analytics.identify(userId.toString(), traits);
  }
}
